import React from 'react';
import { makeDownloadButton } from 'cccisd-tabletop';
import orgsQuery from './orgsQuery.graphql';
import style from './style.css';

export default class DownloadAllOrgInfo extends React.Component {
    getDownloadColumns = () => {
        return [
            { label: 'Organization_Id', name: 'fields.orgId' },
            { label: 'Organization_Name', name: 'group.label' },
            { label: 'Organization_Access_Code', name: 'fields.orgAccessCode' },
            { label: 'Shipping_Address_1', name: 'fields.orgShippingAddress1' },
            { label: 'Shipping_Address_2', name: 'fields.orgShippingAddress2' },
            { label: 'Shipping_City', name: 'fields.orgShippingCity' },
            { label: 'Shipping_State', name: 'fields.orgShippingState' },
            { label: 'Shipping_Zip_Code', name: 'fields.orgShippingZipCode' },
            { label: 'Region', name: 'fields.region' },
            { label: 'Service_Unit', name: 'fields.serviceUnit' },
            { label: 'RegistrationComplete', name: 'fields.registrationComplete' },

            { label: 'orgMemberStartedCount', name: 'fields.orgStartedCount' },
            { label: 'orgMemberPartialCount', name: 'fields.orgPartialCount' },
            { label: 'orgMemberTotalCompletedCount', name: 'fields.orgTotalCompletedCount' },
            { label: 'orgYouthCompletedCount', name: 'fields.orgYouthCompletedCount' },
            { label: 'orgTeenCompletedCount', name: 'fields.orgTeenCompletedCount' },
            {
                label: 'youthResponsesCollectedLastYear',
                name: 'fields.youthResponsesCollectedLastYear',
            },
            {
                label: 'teenResponsesCollectedLastYear',
                name: 'fields.teenResponsesCollectedLastYear',
            },
            { label: 'staffSurveyCount', name: 'fields.staffSurveyCount' },
        ];
    };

    renderDownloadButton = () => {
        const data = {
            tableProps: {
                columns: this.getDownloadColumns(),
                csvFilename: 'organizations.csv',
                query: orgsQuery,
            },
            buttonName: 'Download Info On All Orgs',
            buttonClass: 'btn btn-primary',
        };

        const button = makeDownloadButton(data);
        const getActualVariables = () => {
            return {
                graphqlVariables: {
                    orderBy: [{ field: 'fields.orgId', direction: 'ASC' }],
                },
            };
        };

        const Button = button({ getActualVariables });
        return Button;
    };

    render() {
        return <span className={style.btn}>{this.renderDownloadButton()}</span>;
    }
}
