import React from 'react';
import _get from 'lodash/get';

/*
 * Total the counts for the staff survey
 */
export default settings => {
    return ({ value, row }) => {
        let surveyCount = 0;

        const staffSurveyList = _get(row, settings.listPath);

        staffSurveyList.forEach(survey => {
            if (row[settings.namePath]) {
                // check club name to count for clubs
                if (
                    survey.metrics.devTags.StaffSurveyClubName === row[settings.namePath] &&
                    survey.flowProgress.completed
                ) {
                    surveyCount++;
                }
            } else if (survey.flowProgress.completed) {
                // total count for orgs
                surveyCount++;
            }
        });

        return <div>{surveyCount}</div>;
    };
};
