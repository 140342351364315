import React from 'react';
import IconWarning from 'cccisd-icons/warning2';
import IconCheckmark from 'cccisd-icons/checkmark';

/*
 * Indicates if the paper surveys have been shipped
 * for this lub.
 *
 * club using paper == yes:
 *     - atleast one tracking number for the club = yes => checkmark
 *     - no tracking number = no => warning
 *
 */
export default settings => {
    return ({ value, row, isCsv }) => {
        if (
            row['fields.surveyMethod'] === null ||
            row['fields.surveyMethod'].toLowerCase() !== 'paper'
        ) {
            return <div>n/a</div>;
        }

        let shipped = false;

        // if atleast one shipment to club
        // then checkmark
        if (value !== null) {
            shipped = true;
        }

        if (isCsv) {
            return shipped;
        }

        return <div>{shipped ? <IconCheckmark /> : <IconWarning />}</div>;
    };
};
