/* eslint-disable no-restricted-globals */
import React from 'react';
import _get from 'lodash/get';
import _isNil from 'lodash/isNil';

/*
 * Displays the Tracking Number Column
 *  - if registration not complete, display empty field
 *  - if registration completed:
 *     if no clubs have paper survey, display empty field
 *     if any clubs have paper survey and no tracking number, dipslay warning
 *     if any clubs have paper survey and tracking number exists, display #
 */
export default settings => {
    return ({ value, row }) => {
        if (value.length === 0) {
            return (
                <div key={row} className="text-center">
                    0
                </div>
            );
        }

        var count = value.reduce((accumulator, item) => {
            let v = `${_get(item, settings.metricName)}`;
            let vNum = parseInt(v, 10);
            if (!_isNil(vNum) && vNum !== 'undefined' && vNum !== null && !isNaN(vNum)) {
                return accumulator + vNum;
            }
            return accumulator + 0;
        }, 0);

        if (!_isNil(count) && count !== 'undefined' && count !== null && !isNaN(count)) {
            count = 0;
        }

        return (
            <div key={row} className="text-center">
                {count}
            </div>
        );
    };
};
