import React from 'react';
import Modal from 'cccisd-modal';
import IconPencil from 'cccisd-icons/pencil';
import FormHandler from './Handler.js';
import Tooltip from 'cccisd-tooltip';

export default props => (
    <Modal
        trigger={
            <Tooltip title="Edit">
                <button type="button" className="btn btn-xs btn-success">
                    <IconPencil />
                </button>
            </Tooltip>
        }
        title="Edit Club"
    >
        <FormHandler {...props} />
    </Modal>
);
