import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Loader from 'cccisd-loader';
import { loadInitialState } from 'js/reducers/admin.js';

export default () => WrappedComponent => {
    class Wrapper extends React.Component {
        static propTypes = {
            loadInitialState: PropTypes.func,
        };

        state = {
            loading: true,
        };

        componentDidMount = async () => {
            await this.props.loadInitialState();
            this.setState({ loading: false });
        };

        render() {
            return (
                <Loader loading={this.state.loading} removeChildren>
                    <WrappedComponent {...this.props} />
                </Loader>
            );
        }
    }

    return connect(
        null,
        { loadInitialState }
    )(Wrapper);
};
