import React, { useState } from 'react';
import IconUser from 'cccisd-icons/user';
import Tooltip from 'cccisd-tooltip';
import { client } from 'cccisd-apollo';
import query from './getOrgAdminPawn.graphql';
import Loader from 'cccisd-loader';
import axios from 'cccisd-axios';

const Boilerplate = window.cccisd && window.cccisd.boilerplate;
const Fortress = window.cccisd && window.cccisd.fortress;

export default props => {
    const [loading, setLoading] = useState(false);

    const userId = props.row['user.userId'];
    const contactPawnId = props.row['pawn.pawnId'];
    const contactPawnHash = props.row['pawn.pawnHash'];

    const getOrgAdminPawn = async () => {
        setLoading(true);

        const response = await client.query({ query, variables: { userId } });
        const list = response.data.roles.orgAdminList;
        let data = {
            pawnId: list.length > 0 ? list[0].pawn.pawnId : contactPawnId,
            pawnHash: list.length > 0 ? list[0].pawn.pawnHash : contactPawnHash,
        };

        const response1 = await axios.post(Boilerplate.route('api.nexus.user.loginAs'), data);
        if (response1.data.status === 'success') {
            window.location.href = Boilerplate.url(Fortress.settings.config.after_login_url);
        }
    };

    return (
        <Loader loading={loading}>
            <Tooltip title="Login As">
                <button type="button" className="btn btn-xs btn-warning" onClick={getOrgAdminPawn}>
                    <IconUser />
                </button>
            </Tooltip>
        </Loader>
    );
};
