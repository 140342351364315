/* eslint-disable global-require */
export default {
    framerate: 24,
    images: [require('./cornerSprite.png')],
    frames: [
        [297, 582, 291, 286, 0, 71, 2],
        [592, 1162, 291, 286, 0, 71, 2],
        [297, 1162, 291, 286, 0, 71, 2],
        [2, 1162, 291, 286, 0, 71, 2],
        [1477, 872, 291, 286, 0, 71, 2],
        [1182, 872, 291, 286, 0, 71, 2],
        [887, 872, 291, 286, 0, 71, 2],
        [592, 872, 291, 286, 0, 71, 2],
        [297, 872, 291, 286, 0, 71, 2],
        [2, 872, 291, 286, 0, 71, 2],
        [1477, 582, 291, 286, 0, 71, 2],
        [1182, 582, 291, 286, 0, 71, 2],
        [887, 582, 291, 286, 0, 71, 2],
        [592, 582, 291, 286, 0, 71, 2],
        [887, 1162, 291, 286, 0, 71, 2],
        [2, 582, 291, 286, 0, 71, 2],
        [1477, 292, 291, 286, 0, 71, 2],
        [1182, 292, 291, 286, 0, 71, 2],
        [887, 292, 291, 286, 0, 71, 2],
        [592, 292, 291, 286, 0, 71, 2],
        [297, 292, 291, 286, 0, 71, 2],
        [2, 292, 291, 286, 0, 71, 2],
        [1477, 2, 291, 286, 0, 71, 2],
        [1182, 2, 291, 286, 0, 71, 2],
        [887, 2, 291, 286, 0, 71, 2],
        [592, 2, 291, 286, 0, 71, 2],
        [297, 2, 291, 286, 0, 71, 2],
        [2, 2, 291, 286, 0, 71, 2],
    ],
    animations: {
        'speech loop': {
            frames: [
                1, 1, 2, 2, 2, 3, 3, 3, 4, 4, 5, 5, 6, 6, 6, 7, 7, 8, 8, 3, 3, 3, 9, 9, 10, 10, 10, 11, 11, 12, 12, 12,
                13, 13, 14, 14, 15, 15, 15, 16, 16, 17, 17, 17, 18, 18, 15, 15, 15, 19, 20, 21, 22, 23, 24, 25, 0, 0, 1,
                1, 2, 2, 2, 3, 3, 3, 4, 4, 5, 5, 6, 6, 6, 7, 7, 8, 8, 3, 3, 3, 9, 9, 10, 10, 10, 11, 11, 12, 12, 12, 13,
                13, 14, 14, 15, 15, 15, 16, 16, 17, 17, 17, 18, 18, 15, 15, 15, 19, 19, 26, 26, 27, 27, 27,
            ],
            speed: 1,
        },
        still: { frames: [0, 0, 0], speed: 1 },
    },
};
