import React from 'react';
import { makeDownloadButton } from 'cccisd-tabletop';
import clubsQuery from './clubsQuery.graphql';
import style from './style.css';

export default class DownloadFall2020Info extends React.Component {
    getDownloadColumns = () => {
        const basePath = window.cccisd.boilerplate.url('fall2020/');

        return [
            { label: 'Organization_Id', name: 'ancestorGroups.organization.fields.orgId' },
            { label: 'Club_Id', name: 'fields.clubId' },
            { label: 'Club_Name', name: 'group.label' },
            {
                label: 'fall_survey_url',
                name: 'fall2020',
                render: ({ value, row }) => {
                    return `${basePath}${row['fields.clubHash']}`;
                },
            },
        ];
    };

    renderDownloadButton = () => {
        const data = {
            tableProps: {
                columns: this.getDownloadColumns(),
                csvFilename: 'clubs_fall_2020.csv',
                query: clubsQuery,
            },
            buttonName: 'Download Fall 2020 Info',
            buttonClass: 'btn btn-success',
        };

        const button = makeDownloadButton(data);
        const getActualVariables = () => {
            return {
                graphqlVariables: {
                    deploymentHandle: 'fall2020Survey',
                },
            };
        };

        const Button = button({ getActualVariables });
        return Button;
    };

    render() {
        return <div className={style.btn}>{this.renderDownloadButton()}</div>;
    }
}
