import React from 'react';

/*
 * Displays the value provided with no manipulation
 * The value is centered and default styles
 */
export default settings => {
    return ({ value, row }) => {
        let accessCode = value;
        if (row['fields.surveyMethod'] === 'Paper') {
            accessCode = 'N/A';
        }

        return <div>{accessCode}</div>;
    };
};
