import React from 'react';

/*
 * Displays the list of optional modules in a nice text format
 */
export default settings => {
    return ({ value, row }) => {
        let optionalModules = [];
        if (row['fields.surveyMethod'] === 'paper') {
            return <div>N/A</div>;
        }

        if (row['fields.artsModuleSelected'] === true) {
            optionalModules.push('The Arts');
        }
        if (row['fields.bullyingModuleSelected'] === true) {
            optionalModules.push('Bullying');
        }
        if (row['fields.stemModuleSelected'] === true) {
            optionalModules.push('STEM');
        }
        if (row['fields.riskBehaviorModuleSelected'] === true) {
            optionalModules.push('Risk Behavior');
        }
        if (row['fields.legacyModuleSelected'] === true) {
            optionalModules.push('Legacy');
        }
        if (row['fields.essentialSkillsModuleSelected'] === true) {
            optionalModules.push('Essential Skills');
        }

        return (
            <div>
                {optionalModules.map(item => {
                    return <div key={item}>{item}</div>;
                })}
            </div>
        );
    };
};
