import React from 'react';
import PropTypes from 'prop-types';
import Sprite from 'cccisd-sprite';

export default class Component extends React.Component {
    static propTypes = {
        isTalking: PropTypes.bool,
        talkAnimation: PropTypes.string,
        idleAnimation: PropTypes.string,
        spriteProps: PropTypes.object,
    };

    static defaultProps = {
        isTalking: false,
    };

    componentDidMount = () => {
        this.play();
    };

    componentDidUpdate = prevProps => {
        if (prevProps.isTalking !== this.props.isTalking) {
            this.play();
        }
    };

    play = () => {
        const animation = this.props.isTalking ? this.props.talkAnimation : this.props.idleAnimation;
        this.sprite.play(animation);
    };

    render() {
        return (
            <Sprite
                {...this.props.spriteProps}
                ref={element => {
                    this.sprite = element;
                }}
            />
        );
    }
}
