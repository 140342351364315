/* eslint-disable global-require */
import React from 'react';
import cornerJson from './corner.js';
import sideJson from './side.js';
import SpriteWrapper from '../../SpriteWrapper';
import style from './style.css';

export default {
    name: 'Ollie Bgca',
    Corner: props => (
        <SpriteWrapper
            {...props}
            spriteProps={{
                spriteClass: style.cornerSprite,
                width: 291,
                height: 286,
                zoe: cornerJson,
            }}
            talkAnimation="speech loop"
            idleAnimation="still"
        />
    ),
    Middle: props => (
        <SpriteWrapper
            {...props}
            spriteProps={{
                spriteClass: style.middleSprite,
                width: 638,
                height: 957,
                zoe: sideJson,
            }}
            talkAnimation="Talking Loop (hand on hip)"
            idleAnimation="Still - Pose (hand on hip)"
        />
    ),
    Side: props => (
        <SpriteWrapper
            {...props}
            spriteProps={{
                spriteClass: style.sideSprite,
                width: 638,
                height: 957,
                zoe: sideJson,
            }}
            talkAnimation="Talking Loop (hand on hip)"
            idleAnimation="Still - Pose (hand on hip)"
        />
    ),
    assets: [require('./cornerSprite.png'), require('./sideSprite.png')],
};
