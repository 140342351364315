import React from 'react';
import Modal from 'cccisd-modal';
import PropTypes from 'prop-types';
import ClubForm from 'js/components/ClubForm';
import axios from 'cccisd-axios';
import { connect } from 'react-redux';

const Boilerplate = window.cccisd.boilerplate;

class Component extends React.Component {
    static propTypes = {
        loadData: PropTypes.func,
        modules: PropTypes.array,
    };

    onSubmit = async values => {
        const result = await axios.post(Boilerplate.route('api.nexus.group.store'), {
            ...values,
            label: values.clubName,
            parent: +values.groupId,
            type: 'club',
        });

        if (result.data.errors) {
            throw result.data.errors;
        }
    };

    render() {
        return (
            <Modal
                trigger={
                    <button id="addClubButton" type="button" className="btn btn-primary">
                        Add Club
                    </button>
                }
                title="Add New Club"
            >
                <ClubForm loadData={this.props.loadData} onSubmit={this.onSubmit} />
            </Modal>
        );
    }
}

const mapStateToProps = (state, props) => ({
    modules: state.app.admin.modules,
});

export default connect(mapStateToProps)(Component);
