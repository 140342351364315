import { createSelector } from 'reselect';

const getallowPaperDueDate = state => state.data.dates.allowPaperDueDate;
const getCurrentDate = state => state.currentDate;

export const getAllowPaper = createSelector(
    getallowPaperDueDate,
    getCurrentDate,
    (allowPaperDueDate, currentDate) => {
        return currentDate <= allowPaperDueDate;
    }
);
