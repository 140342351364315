import React from 'react';
import PropTypes from 'prop-types';
import _pick from 'lodash/pick';
import style from './style.css';

export default class Component extends React.Component {
    static propTypes = {
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        onChange: PropTypes.func,
        label: PropTypes.node,
        disabled: PropTypes.bool,
    };

    static defaultProps = {
        onChange: () => {},
    };

    onChange = e => {
        if (this.props.disabled) {
            return;
        }

        this.props.onChange(e);
    };

    render() {
        const labelProps = _pick(this.props, ['id', 'style']);

        const toggle = (
            <label className={style.toggle} {...labelProps}>
                <input
                    type="checkbox"
                    checked={!!this.props.value}
                    value={!!this.props.value}
                    onChange={this.onChange}
                />
                <span className={style.slider}>
                    <span className={style.circle} />
                </span>
            </label>
        );

        if (this.props.label) {
            return (
                <div className={style.wrapper}>
                    <label>
                        {toggle}
                        <span className={style.label}>{this.props.label}</span>
                    </label>
                </div>
            );
        }

        return toggle;
    }
}
