import { handleActions, createAction } from 'redux-actions';
import flatten from 'flat';
import _difference from 'lodash/difference';
import _get from 'lodash/get';
import axios from 'cccisd-axios';
import format from 'date-fns/format';

const Boilerplate = window.cccisd.boilerplate;
const savedParams = _get(window, 'cccisd.bgca.params', {});
const savedMessages = (savedParams.messages || []).reduce((obj, item) => {
    obj[item.handle] = item;
    return obj;
}, {});

export const persistedKeys = [];

// Initial state
export const initialState = {
    data: {
        staffSurveyOn: false,
        memberSurveyOn: true,
        adminCenterOn: true,
        secondarySurveyOn: false,
        ...savedParams,
        messages: [
            {
                handle: 'orgAdminTrainingBoxRequiredInstruction',
                title: 'Organization Admin - Training Box Required Instruction',
                message: `
                        <p>
                            <b>Here's how to sign up:</b>
                        </p>
                        <p>
                            Survey Administration Training is required for all organization admins. Sign up to attend a live training webinar below. If you are unable to attend one of the live webinars, recorded versions will be made available in the Resource Center.
                        </p>
                        `,
            },
            {
                handle: 'orgAdminTrainingRequired',
                title: 'Organization Admin Dashboard - Training Required',
                message: `Welcome to the NYOI Survey online portal. From here you can access survey resources, information, and tools to help you connect and engage with all aspects of the upcoming survey. The portal features progress dashboards, "how-to" documents, trainings, and more.`,
            },
            {
                handle: 'orgAdminTrainingCompleted',
                title: 'Organization Admin Dashboard - Training Completed',
                message: `Message when the training is completed for org admin`,
            },
            {
                handle: 'clubContactTrainingBoxRequiredInstruction',
                title: 'Club Contact - Training Box Required Instruction',
                message: `
                        <p>
                            <b>Here's how to sign up:</b>
                        </p>
                        <p>
                            Survey Administration Training is required for all club contacts. Sign up to attend a live training webinar below. If you are unable to attend one of the live webinars, recorded versions will be made available in the Resource Center.
                        </p>
                        `,
            },
            {
                handle: 'clubContactTrainingRequired',
                title: 'Club Contact Dashboard - Training Required',
                message: 'Message when the training is required for club contact',
            },
            {
                handle: 'clubContactTrainingCompleted',
                title: 'Club Contact Dashboard - Training Completed',
                message: 'Message when the training is completed for club contact',
            },
            {
                handle: 'needHelp',
                title: 'Help Center Text',
                message: 'Some help text',
            },
        ].map(item => ({
            ...item,
            message: savedMessages[item.handle]?.message || item.message,
        })),
        dates: {
            memberSurveyStartDate: '2019-10-11',
            memberSurveyEndDate: '2019-10-12',
            staffSurveyStartDate: '2019-11-11',
            staffSurveyEndDate: '2019-11-12',
            memberSurveyPaperDueDate: '2019-11-11',
            adminCenterStartDate: '2019-12-11',
            adminCenterEndDate: '2019-12-12',
            allowPaperDueDate: '2019-10-10',
            secondarySurveyStartDate: '2020-10-01',
            secondarySurveyEndDate: '2020-10-30',
            ...savedParams.dates,
        },
    },
    currentDate: format(new Date(), 'YYYY-MM-DD'),
};

// Actions
const UPDATE_PARAMS = 'bgca/message/UPDATE_PARAMS';

// Action Creators
export const updateParamsWithoutSaving = createAction(UPDATE_PARAMS);

export const updateParams = params => async (dispatch, getState) => {
    dispatch(updateParamsWithoutSaving(params));
    await axios.post(Boilerplate.route('bgca.set.data'), {
        data: JSON.stringify(params),
    });
};

// Reducer
export default handleActions(
    {
        [UPDATE_PARAMS]: (state, action) => {
            const initialKeys = Object.keys(flatten(initialState.data, { safe: true }));
            const updatedKeys = Object.keys(flatten(action.payload, { safe: true }));
            const diff = _difference(updatedKeys, initialKeys);
            if (diff.length > 0) {
                const msg = 'There are unknown params keys: ' + diff.join(', ');
                console.error(msg);
                throw new Error(msg);
            }

            return {
                ...state,
                data: action.payload,
            };
        },
    },
    initialState
);
