import { createSelector } from 'reselect';
import { validate as validateAddress } from 'js/components/AddressForm';
import _pick from 'lodash/pick';
import _omit from 'lodash/omit';

const getNavItems = state => state.navItems;
const getActiveItem = state => state.activeItem;
const getClubs = state => state.clubs;
const getContacts = state => state.contacts;
const getCurrentContactId = state => state.currentContactId;
const getAddress = state => state.address;
const getMemberStat = state => state.memberStat;
const getActingUserId = state => state.actingUserId;

export const getIsAddressValid = createSelector(getAddress, address => {
    if (!address) {
        return false;
    }

    return Object.keys(validateAddress(address)).length === 0;
});

export const getOrganizationContacts = createSelector(getContacts, contacts =>
    contacts.filter(item => item.role === 'orgAdmin')
);

export const getParticipatingClubs = createSelector(getClubs, clubs =>
    clubs.filter(club => club.participatingThisYear)
);

export const getHasPaperMethod = createSelector(getParticipatingClubs, clubs =>
    clubs.some(club => club.paperEligible && club.surveyMethod === 'paper')
);

export const getAllClubDetails = createSelector(getClubs, getContacts, (clubs, contacts) =>
    clubs.map(item => ({
        ...item,
        contact: contacts.find(obj => obj.id === item.contactUserId) || {},
    }))
);

export const getClubDetails = createSelector(getParticipatingClubs, getContacts, (clubs, contacts) =>
    clubs.map(item => ({
        ...item,
        contact: contacts.find(obj => obj.role === 'clubContact' && obj.userId === item.contactUserId) || {},
    }))
);

export const getContactIdsForClubs = createSelector(getParticipatingClubs, clubs =>
    clubs.map(item => item.contactUserId).filter(item => item)
);

export const getOnlyClubContacts = createSelector(getParticipatingClubs, getContacts, (clubs, contacts) => {
    const addedUsersIds = [];
    const result = [];
    const primaryContacts = contacts.filter(item => item.role === 'clubContact' && item.primaryContact);

    primaryContacts.forEach(contact => {
        if (addedUsersIds.includes(contact.userId)) {
            return;
        }
        const groupIds = primaryContacts.filter(item => item.userId === contact.userId).map(item => item.groupId);

        const userClubs = clubs.filter(club => groupIds.includes(club.groupId));
        if (userClubs.length === 0) {
            return;
        }

        addedUsersIds.push(contact.userId);
        result.push({
            ..._omit(contact, ['id', 'groupId']),
            clubs: userClubs,
        });
    });

    return result;
});

export const getContactsToEmail = createSelector(getOnlyClubContacts, getActingUserId, (contacts, actingUserId) => {
    return contacts.filter(contact => contact.userId !== actingUserId);
});

export const getClubContacts = createSelector(
    getParticipatingClubs,
    getContacts,
    getContactIdsForClubs,
    (clubs, contacts, contactIds) => {
        return contacts
            .filter(item => contactIds.includes(item.id))
            .map(item => ({
                ...item,
                clubs: clubs.filter(club => club.contactUserId === item.id),
            }));
    }
);

export const getPossibleClubContacts = createSelector(getContacts, contacts => {
    const addedUserIds = [];
    const result = [];

    contacts.forEach(contact => {
        if (addedUserIds.includes(contact.userId)) {
            return;
        }

        addedUserIds.push(contact.userId);
        result.push(_pick(contact, ['userId', 'firstName', 'lastName', 'email']));
    });

    return result;
});

export const getRegistrationStatus = createSelector(
    getHasPaperMethod,
    getIsAddressValid,
    getParticipatingClubs,
    getOrganizationContacts,
    (hasPaperMethod, isAddressValid, clubs, contacts) => {
        const steps = ['contacts', 'participation', 'details', 'training'];
        return steps.map(item => {
            const result = {
                step: item,
            };
            if (item === 'contacts') {
                if (contacts.some(contact => contact.primaryContact)) {
                    result.done = true;
                } else {
                    result.done = false;
                }
            }
            if (item === 'participation') {
                if (!hasPaperMethod || isAddressValid) {
                    result.done = true;
                } else {
                    result.done = false;
                }
            }
            if (item === 'details') {
                if (clubs.every(club => club.contactUserId && club.consentForm)) {
                    result.done = true;
                } else {
                    result.done = false;
                }
            }
            if (item === 'training') {
                result.done = true;
            }
            return result;
        });
    }
);

export const getCurrentContact = createSelector(
    getContacts,
    getCurrentContactId,
    getParticipatingClubs,
    (contacts, currentContactId, clubs) => {
        const result = contacts.find(item => item.id === currentContactId) || {};
        result.clubs = clubs.filter(item => item.contactUserId === result.userId);

        // we need to return different result every time
        return { ...result };
    }
);

export const getActualNavItems = createSelector(
    getNavItems,
    getHasPaperMethod,
    getCurrentContact,
    (navItems, hasPaperMethod, currentContact) => {
        const isTrainingRequired = currentContact.trainingRequired && !currentContact.trainingComplete;

        let result = navItems.filter(item => item.role.includes(currentContact.role));
        if (!hasPaperMethod) {
            result = result.filter(item => item.code !== 'shipping');
        }
        if (isTrainingRequired) {
            result = result.map(item => ({
                ...item,
                disabled: !['clubDashboard', 'dashboard', 'resource', 'manage', 'shipping'].includes(item.code),
            }));
        }

        return result;
    }
);

export const getActualActiveItem = createSelector(getActiveItem, getActualNavItems, (activeItem, navItems) => {
    if (!navItems.find(item => item.code === activeItem)) {
        return navItems[0].code;
    }

    return activeItem;
});

export const getAddressAsString = createSelector(getAddress, address => {
    let result = '';

    if (address.address1) {
        result += ', ' + address.address1;
    }
    if (address.address2) {
        result += ', ' + address.address2;
    }
    if (address.city) {
        result += ', ' + address.city;
    }
    if (address.state) {
        result += ', ' + address.state;
    }
    if (address.zip) {
        result += ', ' + address.zip;
    }

    return result.substring(2);
});

export const getClubsTrainingStat = createSelector(getParticipatingClubs, getContacts, (clubs, contacts) => {
    const result = {
        total: clubs.length,
    };

    const readyGroupIds = contacts
        .filter(
            item => item.role === 'clubContact' && item.trainingRequired && item.trainingComplete && item.primaryContact
        )
        .map(item => item.groupId);
    result.ready = clubs.filter(club => readyGroupIds.includes(club.groupId)).length;
    result.notReady = result.total - result.ready;

    return result;
});

export const getNormalizedMemberStat = createSelector(getParticipatingClubs, getMemberStat, (clubs, memberStat) => {
    const result = {
        overall: {
            started: 0,
            inClubPartial: 0,
            inClubCompletedYouth: 0,
            inClubCompletedTeen: 0,
            virtualPartial: 0,
            virtualCompletedYouth: 0,
            virtualCompletedTeen: 0,
            youthResponsesCollectedLastYear: 0,
            teenResponsesCollectedLastYear: 0,
            total: 0,
            totalLastYear: 0,
            percentFromMaximum: 100,
        },
    };
    let maximum = 0;
    clubs.forEach(club => {
        const stat = {
            started: 0,
            inClubPartial: 0,
            inClubCompletedYouth: 0,
            inClubCompletedTeen: 0,
            virtualPartial: 0,
            virtualCompletedYouth: 0,
            virtualCompletedTeen: 0,
            youthResponsesCollectedLastYear: 0,
            teenResponsesCollectedLastYear: 0,
            ...memberStat[club.clubId],
        };
        stat.total =
            stat.started +
            stat.inClubPartial +
            stat.virtualPartial +
            stat.inClubCompletedYouth +
            stat.virtualCompletedYouth +
            stat.inClubCompletedTeen +
            stat.virtualCompletedTeen;
        stat.totalLastYear = stat.youthResponsesCollectedLastYear + stat.teenResponsesCollectedLastYear;

        if (maximum < stat.total) {
            maximum = stat.total;
        }
        if (maximum < stat.totalLastYear) {
            maximum = stat.totalLastYear;
        }

        result.overall.started += stat.started;
        result.overall.inClubPartial += stat.inClubPartial;
        result.overall.inClubCompletedYouth += stat.inClubCompletedYouth;
        result.overall.inClubCompletedTeen += stat.inClubCompletedTeen;
        result.overall.virtualPartial += stat.virtualPartial;
        result.overall.virtualCompletedYouth += stat.virtualCompletedYouth;
        result.overall.virtualCompletedTeen += stat.virtualCompletedTeen;
        result.overall.youthResponsesCollectedLastYear += stat.youthResponsesCollectedLastYear;
        result.overall.teenResponsesCollectedLastYear += stat.teenResponsesCollectedLastYear;
        result.overall.total += stat.total;
        result.overall.totalLastYear += stat.totalLastYear;

        result[club.clubId] = stat;
    });

    clubs.forEach(club => {
        result[club.clubId].percentFromMaximum = Math.round((result[club.clubId].total / maximum) * 10000) / 100;
        result[club.clubId].lastYearPercentFromMaximum =
            Math.round((result[club.clubId].totalLastYear / maximum) * 10000) / 100;
    });

    if (result.overall.total > result.overall.totalLastYear) {
        result.overall.percentFromMaximum = 100;
        result.overall.lastYearPercentFromMaximum =
            Math.round((result.overall.totalLastYear / result.overall.total) * 10000) / 100;
    } else {
        result.overall.lastYearPercentFromMaximum = 100;
        result.overall.percentFromMaximum =
            Math.round((result.overall.total / result.overall.totalLastYear) * 10000) / 100;
    }

    return result;
});

export const getVisibleClubs = createSelector(getParticipatingClubs, getCurrentContact, (clubs, currentContact) => {
    if (currentContact.role === 'orgAdmin') {
        return clubs;
    }

    return currentContact.clubs;
});

export const getTrackingNumbers = createSelector(getParticipatingClubs, clubs => {
    const result = [];
    clubs
        .filter(club => club.surveyMethod === 'paper')
        .forEach(club => {
            for (let item of club.trackingNumbers) {
                result.push({
                    key: `${club.groupId}_${item.trackingNumber}`,
                    clubName: club.clubName,
                    trackingNumber: item.trackingNumber,
                    url: item.url,
                });
            }
        });

    return result;
});
