import React from 'react';
import _round from 'lodash/round';

/*
 * Displays the speed test value in mbps and
 * an indicator if good or poor.
 */
export default settings => {
    return ({ value, row, isCsv }) => {
        if (value === null) {
            return <div />;
        }

        var speed = _round(value / 1000, 2);
        var translate = speed <= 2 ? '(poor)' : '(good)';

        if (isCsv) {
            return speed + ' ' + translate;
        }

        return (
            <div>
                {speed} {translate}
            </div>
        );
    };
};
