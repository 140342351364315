import React from 'react';
import PropTypes from 'prop-types';
import Footer from 'cccisd-footer';
import Header from 'js/components/Header';
import { isIE } from 'js/utils.js';
import style from './style.css';

export default class FixedLayout extends React.Component {
    static propTypes = {
        showSecondaryNav: PropTypes.bool,
        children: PropTypes.node,
    };

    static defaultProps = {
        showSecondaryNav: true,
    };

    render() {
        return (
            <div className={style.body}>
                <div className={style.content}>
                    <Header containerClassName="container" />
                    <div className="container" style={{ marginTop: '1em' }}>
                        {this.props.children}
                    </div>
                </div>
                {!isIE() && (
                    <div className={style.footer}>
                        <Footer className="container" showContactUsLink={false} />
                    </div>
                )}
            </div>
        );
    }
}
