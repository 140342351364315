import React from 'react';
import PropTypes from 'prop-types';
import Form from './form.js';
import { connect } from 'react-redux';
import getAdminInitialState from 'js/helpers/getAdminInitialState';
import { changeClubSettings } from 'js/reducers/admin.js';
import { getAllClubDetails } from 'js/selectors/admin.js';
import _pick from 'lodash/pick';
import _omit from 'lodash/omit';

export const convert = values => ({
    ...values,
    paperOrderEnglishYouth: +values.paperOrderEnglishYouth,
    paperOrderSpanishYouth: +values.paperOrderSpanishYouth,
    paperOrderEnglishTeen: +values.paperOrderEnglishTeen,
    paperOrderSpanishTeen: +values.paperOrderSpanishTeen,
});

class Component extends React.Component {
    static propTypes = {
        clubs: PropTypes.array,
        row: PropTypes.object,
        loadData: PropTypes.func,
        modules: PropTypes.array,
        closeModal: PropTypes.func,
        changeClubSettings: PropTypes.func,
    };

    onSubmit = async values => {
        let settings = {};
        if (!values.participatingThisYear) {
            settings = _pick(values, ['participatingThisYear']);
        } else if (values.surveyMethod === 'paper') {
            settings = _omit(values, ['selectedModules']);
        } else if (values.surveyMethod === 'online') {
            settings = _omit(values, [
                'paperOrderEnglishYouth',
                'paperOrderSpanishYouth',
                'paperOrderEnglishTeen',
                'paperOrderSpanishTeen',
            ]);
        }

        await this.props.changeClubSettings({
            groupId: this.props.row['group.groupId'],
            settings,
        });

        await this.props.loadData();

        this.props.closeModal();
    };

    getInitialValues = () => {
        const { row, modules } = this.props;
        const selectedModules = modules
            .filter(item => row[`fields.${item.code}ModuleSelected`])
            .map(item => item.code);

        return {
            surveyMethod: row['fields.surveyMethod'],
            participatingThisYear: row['fields.participatingThisYear'],
            consentForm: row['fields.consentForm'],
            selectedModules,
            paperOrderEnglishYouth: row['fields.paperOrderEnglishYouth'] || 0,
            paperOrderSpanishYouth: row['fields.paperOrderSpanishYouth'] || 0,
            paperOrderEnglishTeen: row['fields.paperOrderEnglishTeen'] || 0,
            paperOrderSpanishTeen: row['fields.paperOrderSpanishTeen'] || 0,
        };
    };

    render() {
        const currentClub = this.props.clubs.find(
            item => item.groupId === this.props.row['group.groupId']
        );

        // disable changing the selection of optional modules if any students have
        // taken the survey at the club
        const disableUpdating = this.props.row['descendantRoles.studentList'].length > 0;

        return (
            <Form
                initialValues={this.getInitialValues()}
                onSubmit={this.onSubmit}
                modules={this.props.modules}
                club={currentClub}
                disableUpdating={disableUpdating}
            />
        );
    }
}

const mapStateToProps = (state, props) => ({
    clubs: getAllClubDetails(state.app.admin),
    modules: state.app.admin.modules,
});

export default getAdminInitialState()(
    connect(
        mapStateToProps,
        { changeClubSettings }
    )(Component)
);
