import React from 'react';
import IconWarning from 'cccisd-icons/warning2';
import IconCheckmark from 'cccisd-icons/checkmark';

/*
 * Indicates if the paper surveys have been shipped to the Org if
 * any clubs are using survey method of paper.
 *
 * any club using paper == yes:
 *     - atleast one tracking number for the club = yes => checkmark
 *     - no tracking number = no => warning
 *
 * no clubs using paper:
 *     - paper shipped => n/a
 */
export default settings => {
    return ({ value, row, isCsv }) => {
        const clubs = row['descendantGroups.clubList'];

        let paperClubs = clubs.filter(club => {
            return club.fields.surveyMethod.toLowerCase() === 'paper';
        });

        // if any shipment sent to any club
        // then checkmark
        if (paperClubs.length > 0) {
            let shipped = false;
            shipped = paperClubs.some(club => {
                return club.fields.trackingNumbers !== null;
            });

            if (isCsv) {
                return shipped;
            }

            return <div>{shipped ? <IconCheckmark /> : <IconWarning />}</div>;
        }

        // no clubs using paper
        return <div>n/a</div>;
    };
};
