/* eslint-disable global-require */
export default {
    framerate: 24,
    images: [require('./sideSprite.png')],
    frames: [
        [3212, 963, 638, 957, 0, -766, -188],
        [1928, 2885, 638, 957, 0, -766, -188],
        [1286, 2885, 638, 957, 0, -766, -188],
        [644, 2885, 638, 957, 0, -766, -188],
        [2, 2885, 638, 957, 0, -766, -188],
        [3212, 1924, 638, 957, 0, -766, -188],
        [2570, 1924, 638, 957, 0, -766, -188],
        [1928, 1924, 638, 957, 0, -766, -188],
        [1286, 1924, 638, 957, 0, -766, -188],
        [644, 1924, 638, 957, 0, -766, -188],
        [2, 1924, 638, 957, 0, -766, -188],
        [2570, 2885, 638, 957, 0, -766, -188],
        [2570, 963, 638, 957, 0, -766, -188],
        [1928, 963, 638, 957, 0, -766, -188],
        [1286, 963, 638, 957, 0, -766, -188],
        [644, 963, 638, 957, 0, -766, -188],
        [2, 963, 638, 957, 0, -766, -188],
        [3212, 2, 638, 957, 0, -766, -188],
        [2570, 2, 638, 957, 0, -766, -188],
        [1928, 2, 638, 957, 0, -766, -188],
        [1286, 2, 638, 957, 0, -766, -188],
        [644, 2, 638, 957, 0, -766, -188],
        [2, 2, 638, 957, 0, -766, -188],
    ],
    animations: {
        'Talking Loop (hand on hip)': {
            frames: [
                1, 1, 2, 2, 2, 3, 3, 3, 4, 4, 5, 5, 6, 6, 6, 1, 1, 7, 7, 3, 3, 3, 8, 8, 9, 9, 9, 0, 0, 3, 3, 3, 10, 10,
                11, 11, 12, 12, 12, 13, 13, 14, 14, 14, 15, 15, 12, 12, 12, 16, 17, 18, 19, 20, 21, 22, 0, 0, 1, 1, 2,
                2, 2, 3, 3, 3, 4, 4, 5, 5, 6, 6, 6, 1, 1, 7, 7, 3, 3, 3, 8, 8, 9, 9, 9, 0, 0, 3, 3, 3, 10, 10, 11, 11,
                12, 12, 12, 13, 13, 14, 14, 14, 15, 15, 12, 12, 12, 16, 16, 10, 10, 5, 5, 5,
            ],
            speed: 1,
        },
        'Still - Pose (hand on hip)': {
            speed: 1,
            frames: [0],
        },
    },
};
