import React from 'react';
import { TextBlock } from 'cccisd-redux-form-helpers';
import Tooltip from 'cccisd-tooltip';

export default props => {
    const { field, label, disabled } = props;
    const disabledMessage = 'Paper registration has closed or orders have been already shipped';

    const input = (
        <TextBlock field={field} label={label} style={{ maxWidth: '100px' }} disabled={disabled} />
    );

    if (disabled) {
        return <Tooltip title={disabledMessage}>{input}</Tooltip>;
    }

    return input;
};
