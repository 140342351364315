/* eslint-disable no-restricted-globals */
import React from 'react';

/*
 * Displays the Tracking Numbers for a club
 */
export default settings => {
    return ({ value, row, isCsv }) => {
        if (value.length === 0) {
            return '';
        }

        let trackingNumbers = JSON.parse(value);
        let trackingList = [];
        for (let i = 0; i < trackingNumbers.length; i += 1) {
            let t = [];
            t.trackingNumber = trackingNumbers[i].trackingNumber;
            t.url = trackingNumbers[i].url + trackingNumbers[i].trackingNumber;
            trackingList.push(t);
        }

        if (isCsv) {
            let csvData = trackingList
                .map(tn => {
                    return tn.url;
                })
                .join('\n');
            return csvData;
        }

        return (
            <div>
                {trackingList.map(item => {
                    return (
                        <div key={item}>
                            <li>
                                <a href={item.url} target="_blank" rel="noopener noreferrer">
                                    {item.trackingNumber}
                                </a>
                            </li>
                        </div>
                    );
                })}
            </div>
        );
    };
};
