import format from 'date-fns/format';
import md5 from 'md5';

export const formatDate = date => format(date, 'MMMM D, YYYY');

export const isIE = () => {
    const userAgent = window.navigator.userAgent;
    return userAgent.includes('MSIE ') || userAgent.includes('Trident/');
};

export const getHashForClubCode = clubCode => {
    const hashKey = 'ShksdfhksyisiyIYsidyfsfh*sdh8676s';
    return md5(hashKey + clubCode).substr(0, 5);
};
