import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'cccisd-modal';
import TrackingForm from 'js/components/TrackingNumbersForm';
import IconPencil from 'cccisd-icons/pencil';
import axios from 'cccisd-axios';
import { connect } from 'react-redux';
import Tooltip from 'cccisd-tooltip';
import _mapKeys from 'lodash/mapKeys';

const Boilerplate = window.cccisd.boilerplate;

class Component extends React.Component {
    static propTypes = {
        row: PropTypes.object,
        loadData: PropTypes.func,
        modules: PropTypes.array,
    };

    onSubmit = async values => {
        const result = await axios.put(Boilerplate.route('club.update.tracking.numbers'), {
            ...values,
        });

        if (result.data.errors) {
            const errors = _mapKeys(result.data.errors, (value, key) => {
                if (key === 'Club ID') {
                    return 'clubId';
                }
                if (key === 'label') {
                    return 'clubName';
                }

                return key;
            });

            throw errors;
        }
    };

    getInitialValues = () => {
        const { row } = this.props;

        return {
            groupId: row['group.groupId'],
            clubId: row['fields.clubId'],
            clubName: row['group.label'],
            trackingNumbers: JSON.parse(row['fields.trackingNumbers']) || [],
        };
    };

    render() {
        const initialValues = this.getInitialValues();
        return (
            <Modal
                trigger={
                    <Tooltip title="Edit">
                        <button type="button" className="btn btn-xs btn-success">
                            <IconPencil />
                        </button>
                    </Tooltip>
                }
                title="Edit tracking numbers"
            >
                <TrackingForm
                    initialValues={initialValues}
                    loadData={this.props.loadData}
                    onSubmit={this.onSubmit}
                />
            </Modal>
        );
    }
}

const mapStateToProps = (state, props) => ({});

export default connect(mapStateToProps)(Component);
