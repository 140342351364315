import { handleActions, createAction } from 'redux-actions';
import _isString from 'lodash/isString';
import _pick from 'lodash/pick';
import axios from 'cccisd-axios';

const Boilerplate = window.cccisd.boilerplate;

export const persistedKeys = ['clubAccessId', 'isPlainSurvey'];

// Initial state
export const initialState = {
    currentDate: new Date().getTime(),
    clubAccessId: '',
    clubInfo: {},
    memberId: '0',
    memberPawnId: 0,
    memberPawnHash: '',
    birthday: '',
    language: 'en',
    page: 'pickMemberId',
    pageProps: {},
    isPlainSurvey: false,
};

// Actions
const SET_CLUB_INFO = 'bgca/bgClub/SET_CLUB_INFO';
const SET_MEMBER_ID = 'bgca/bgClub/SET_MEMBER_ID';
const SET_MEMBER_PAWN_ID = 'bgca/bgClub/SET_MEMBER_PAWN_ID';
const SET_MEMBER_PAWN_HASH = 'bgca/bgClub/SET_MEMBER_PAWN_HASH';
const SET_BIRTHDAY = 'bgca/bgClub/SET_BIRTHDAY';
const SET_LANGUAGE = 'bgca/bgClub/SET_LANGUAGE';
const GO_TO_PAGE = 'bgca/bgClub/GO_TO_PAGE';
const RESET_MEMBER_INFO = 'bgca/bgClub/RESET_MEMBER_INFO';
const SET_IS_PLAIN_SURVEY = 'bgca/bgClub/SET_IS_PLAIN_SURVEY';

// Action Creators
export const setClubInfo = createAction(SET_CLUB_INFO);
export const setMemberId = createAction(SET_MEMBER_ID);
export const setMemberPawnId = createAction(SET_MEMBER_PAWN_ID);
export const setMemberPawnHash = createAction(SET_MEMBER_PAWN_HASH);
export const setBirthday = createAction(SET_BIRTHDAY);
export const setLanguage = createAction(SET_LANGUAGE);
export const goToPage = createAction(GO_TO_PAGE);
export const resetMemberInfo = createAction(RESET_MEMBER_INFO);
export const setIsPlainSurvey = createAction(SET_IS_PLAIN_SURVEY);

export const getMembers = () => {
    return async (dispatch, getState) => {
        const { clubInfo, memberId } = getState().app.bgClub;
        const routeName = 'get.club.student';

        const result = await axios.get(
            Boilerplate.route(routeName, {
                groupId: clubInfo.groupId,
                memberId,
            })
        );

        return result.data.students.map(item => ({
            pawnId: item.pawnId,
            pawnHash: item.pawnHash,
            birthday: item.birthday,
            language: item.language,
            isFlowFinished: !!item.isFlowFinished,
        }));
    };
};

export const saveScreenedUser = () => {
    return async (dispatch, getState) => {
        const state = getState().app.bgClub;
        const params = {
            ..._pick(state, ['memberId', 'birthday']),
            role: 'student',
            setAsBehalf: true,
            screened: true,
            group: state.clubInfo.groupId,
            selfRoster: true,
        };

        let result = await axios.post(Boilerplate.route('api.nexus.pawn.store'), params);

        let apParams = {
            pawnId: result.data.data.id,
            deploymentId: 71, // youth-main-2024
            flowlist: 'default',
            language: 'en',
        };

        await axios.post(Boilerplate.route('api.assignmentProgress.update'), apParams);
    };
};

export const useNewPawnId = (role = 'student') => {
    return async (dispatch, getState) => {
        const state = getState().app.bgClub;
        const params = {
            ..._pick(state, ['memberId', 'birthday', 'language']),
            role,
            setAsBehalf: true,
            screened: false,
            group: state.clubInfo.groupId,
            selfRoster: true,
        };

        const result = await axios.post(Boilerplate.route('api.nexus.pawn.store'), params);

        const pawnId = result.data.data.id;
        const pawnHash = result.data.data.random_hash;
        dispatch(setMemberPawnId(pawnId));
        dispatch(setMemberPawnHash(pawnHash));
    };
};

export const updatePawnId = () => {
    return async (dispatch, getState) => {
        const { memberPawnId, memberPawnHash, language } = getState().app.bgClub;

        await axios.put(Boilerplate.route('api.nexus.pawn.update', { pawn: memberPawnId }), {
            language,
            pawnHash: memberPawnHash,
            screened: false,
        });
    };
};

export const finishSurvey = () => {
    return async (dispatch, getState) => {
        const state = getState().app.bgClub;

        if (!state.memberPawnId) {
            return;
        }

        await axios.put(Boilerplate.route('api.nexus.pawn.update', { pawn: state.memberPawnId }), {
            pawnId: state.memberPawnId,
            pawnHash: state.memberPawnHash,
            screened: false,
            isFlowFinished: true,
        });
    };
};

export const loadClubInfo = (organizerUrl = '/organizer') => {
    return async (dispatch, getState) => {
        const { clubAccessId } = getState().app.bgClub;

        // Redirect to organizer if clubAccessId is not a 6-digit number
        if (!/^\d{6}$/.test(clubAccessId)) {
            window.location.replace(Boilerplate.url(organizerUrl));
        }

        const result = await axios.get(Boilerplate.route('get.club.info', { accessCodeId: clubAccessId }));

        if (
            result.data.status !== 'success' ||
            result.data.club.participatingThisYear !== 1 ||
            result.data.club.surveyMethod !== 'online'
        ) {
            // Redirect to organizer if we cannon find this club
            window.location.replace(Boilerplate.url(organizerUrl));
        } else {
            dispatch(setClubInfo(result.data.club));
        }
    };
};

// Reducer
export default handleActions(
    {
        [SET_CLUB_INFO]: (state, action) => ({
            ...state,
            clubAccessId: action.payload.clubAccessId,
            clubInfo: _pick(action.payload, [
                'groupId',
                'clubId',
                'clubAccessId',
                'label',
                'artsModuleSelected',
                'bullyingModuleSelected',
                'ilModuleSelected',
                'riskBehaviorModuleSelected',
                'stemModuleSelected',
                'legacyModuleSelected',
                'essentialSkillsModuleSelected',
                'downloadSpeedCount',
                'downloadSpeedHistory',
                'avgDownloadSpeed',
            ]),
        }),
        [SET_MEMBER_ID]: (state, action) => ({
            ...state,
            memberId: action.payload.replace(/^0+/, '').toLowerCase(),
        }),
        [SET_MEMBER_PAWN_ID]: (state, action) => ({
            ...state,
            memberPawnId: action.payload,
        }),
        [SET_MEMBER_PAWN_HASH]: (state, action) => ({
            ...state,
            memberPawnHash: action.payload,
        }),
        [SET_BIRTHDAY]: (state, action) => ({
            ...state,
            birthday: action.payload,
        }),
        [SET_LANGUAGE]: (state, action) => ({
            ...state,
            language: action.payload,
        }),
        [SET_IS_PLAIN_SURVEY]: (state, action) => {
            const isPlainSurvey = !!action.payload;

            if (state.isPlainSurvey === isPlainSurvey) {
                return state;
            }

            return {
                ...state,
                isPlainSurvey,
            };
        },
        [GO_TO_PAGE]: (state, action) => {
            const { name, props = {} } = _isString(action.payload) ? { name: action.payload } : action.payload;

            return {
                ...state,
                page: name,
                pageProps: props,
            };
        },
        [RESET_MEMBER_INFO]: (state, action) => ({
            ...state,
            ..._pick(initialState, [
                'memberId',
                'memberPawnId',
                'memberPawnHash',
                'birthday',
                'language',
                'page',
                'pageProps',
            ]),
        }),
    },
    initialState
);
