import { handleActions, createAction } from 'redux-actions';

export const persistedKeys = ['activeStep', 'latestStep'];

// Initial state
export const initialState = {
    navSteps: [
        { code: 'welcome', title: 'Welcome' },
        { code: 'contacts', title: 'Organization Admins' },
        { code: 'participation', title: 'Club Participation' },
        { code: 'details', title: 'Club Details' },
        { code: 'training', title: 'Training Requirements' },
        {
            code: 'complete',
            linkToStepTitle: 'Done with Training Requirements',
            title: 'Registration Complete',
        },
    ],
    activeStep: 'welcome',
    latestStep: 'welcome',
};

// Actions
const SET_ACTIVE_STEP = 'bgca/registration/SET_ACTIVE_STEP';
const GO_TO_NEXT_STEP = 'bgca/registration/GO_TO_NEXT_STEP';
const GO_TO_PREV_STEP = 'bgca/registration/GO_TO_PREV_STEP';

// Action Creators
export const setActiveStep = createAction(SET_ACTIVE_STEP);
export const goToNextStep = createAction(GO_TO_NEXT_STEP);
export const goToPrevStep = createAction(GO_TO_PREV_STEP);

// Reducer
export default handleActions(
    {
        [SET_ACTIVE_STEP]: (state, action) => {
            const newActiveStep = action.payload;
            if (state.activeStep === newActiveStep || !state.navSteps.find(item => item.code === newActiveStep)) {
                return state;
            }

            return {
                ...state,
                activeStep: newActiveStep,
            };
        },
        [GO_TO_NEXT_STEP]: (state, action) => {
            const index = state.navSteps.findIndex(item => item.code === state.activeStep);
            if (index === state.navSteps.length - 1) {
                return state;
            }

            const latestIndex = state.navSteps.findIndex(item => item.code === state.latestStep);
            const nextCode = state.navSteps[index + 1].code;

            return {
                ...state,
                activeStep: nextCode,
                ...(latestIndex <= index + 1 && { latestStep: nextCode }),
            };
        },
        [GO_TO_PREV_STEP]: (state, action) => {
            const index = state.navSteps.findIndex(item => item.code === state.activeStep);
            if (index === 0) {
                return state;
            }

            return {
                ...state,
                activeStep: state.navSteps[index - 1].code,
            };
        },
    },
    initialState
);
