import React from 'react';
import axios from 'cccisd-axios';
import ClickButton from 'cccisd-click-button';
import notification from 'cccisd-notification';

const Boilerplate = window.cccisd.boilerplate;

export default class DownloadPaperShipmentInfo extends React.Component {
    static propTypes = {};

    static defaultProps = {
        // default props
    };

    onSubmit = async () => {
        let arrayColumns = [
            { label: 'Organization ID', name: 'ancestorGroups.organization.fields.orgId' },
            { label: 'Organization Name', name: 'ancestorGroups.organization.group.label' },
            {
                label: 'Org Admin Name',
                name: 'ancestorGroups.organization.childRoles.orgAdmin.user.fullName',
            },
            {
                label: 'Org Address 1',
                name: 'ancestorGroups.organization.fields.orgShippingAddress1',
            },
            {
                label: 'Org Address 2',
                name: 'ancestorGroups.organization.fields.orgShippingAddress2',
            },
            { label: 'Org City', name: 'ancestorGroups.organization.fields.orgShippingCity' },
            { label: 'Org State', name: 'ancestorGroups.organization.fields.orgShippingState' },
            { label: 'Org ZipCode', name: 'ancestorGroups.organization.fields.orgShippingZipCode' },
            {
                label: 'Org Admin Phone Number',
                name: 'ancestorGroups.organization.childRoles.orgAdmin.fields.phone',
            },
            { label: 'Site_ID', name: 'fields.clubId' },
            { label: 'Club_Name', name: 'group.label' },
            { label: 'Survey_Method', name: 'fields.surveyMethod' },
            { label: 'English_Youth', name: 'fields.paperOrderEnglishYouth' },
            { label: 'Spanish_Youth', name: 'fields.paperOrderSpanishYouth' },
            { label: 'English_Teen', name: 'fields.paperOrderEnglishTeen' },
            { label: 'Spanish_Teen', name: 'fields.paperOrderSpanishTeen' },
        ];

        let columns = JSON.stringify(arrayColumns);

        let query =
            'query ($limit: Int, $offset: Int, $filter: Filter, $orderBy: [OrderBy]) { groups(as: uberadmin) { clubList(limit: $limit, offset: $offset, orderBy: $orderBy, filter: {OR: [{notNull: {field: "fields.trackingNumbers"}}, {eq: {field: "fields.surveyMethod", string: "paper"}}]}) { group { groupId label } fields { clubId surveyMethod paperOrderEnglishYouth paperOrderSpanishYouth paperOrderEnglishTeen paperOrderSpanishTeen } ancestorGroups { organization { group { groupId label } fields { orgId orgShippingAddress1 orgShippingAddress2 orgShippingCity orgShippingState orgShippingZipCode } childRoles { orgAdmin(filter: {eq: {field: "fields.primaryContact", boolean: true}}) { user { fullName phone } fields { primaryContact } } } } } } totalCount: clubCount filteredCount: clubCount(filter: $filter) }}';

        let variables =
            '{"orderBy": [{"field": "ancestorGroups.organization.fields.orgId", "direction": "ASC"}]}';

        const response = await axios.post(Boilerplate.route('graphql.generatecsv'), {
            columns,
            query,
            variables,
        });

        const url = response.data.url;
        if (url) {
            // redirect to download file
            window.location.href = url;
        } else {
            let errorMessage = response.data;
            if (typeof errorMessage !== 'string') {
                errorMessage = 'Error encountered when generating CSV. Please contact support.';
            }
            notification({ message: errorMessage, type: 'danger' });
            console.error(errorMessage);
        }
    };

    render() {
        return (
            <ClickButton
                className="btn btn-primary"
                id="DownloadPaperShipmentInfo"
                title="Download Paper Shipment Info"
                onClick={this.onSubmit}
            />
        );
    }
}
