import bgClubReducer, { persistedKeys as bgClubPersistedKeys } from './reducers/bgClub.js';
import registrationReducer, {
    persistedKeys as registrationPersistedKeys,
} from './reducers/registration.js';
import adminReducer, { persistedKeys as adminPersistedKeys } from './reducers/admin.js';
import paramsReducer, { persistedKeys as paramsPersistedKeys } from './reducers/params.js';

export default {
    bgClub: {
        reducer: bgClubReducer,
        persist: { keys: bgClubPersistedKeys },
    },
    registration: {
        reducer: registrationReducer,
        persist: { keys: registrationPersistedKeys },
    },
    admin: {
        reducer: adminReducer,
        persist: { keys: adminPersistedKeys },
    },
    params: {
        reducer: paramsReducer,
        persist: { keys: paramsPersistedKeys },
    },
};
