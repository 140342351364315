import React from 'react';

/*
 * Displays the boolean value (yes/no)
 */
export default settings => {
    return ({ value, row }) => {
        if (value === true || value === '1') {
            return <div>yes</div>;
        }
        return <div>no</div>;
    };
};
