import React from 'react';
import IconWarning from 'cccisd-icons/warning2';
import IconCheckmark from 'cccisd-icons/checkmark';

/*
 * Returns the number of clubs participating this year at the org that still
 * require training or tht are ready or if all clubs are ready. The value returned
 * depends on the settings passed in.
 *
 * The number that needs training is based on the club contact designated as
 * primary contact.
 *
 * If the primary contact for the club has not completed training
 * or if there is no primary contact
 *     => club not ready
 *
 * If the primary contact for the club has completed training
 *     => club is ready
 */
export default settings => {
    return ({ value, row, isCsv }) => {
        const clubs = row['descendantGroups.clubList'];
        let needTraining = 0;
        let clubsReady = 0;
        let allClubsReady = true;

        clubs.forEach(club => {
            if (club.fields.participatingThisYear === false) {
                // club is not participating so don't track it
                return;
            }

            if (club.descendantRoles.clubContact.pawn.pawnId === null) {
                // no primary contact identified - should never happen
                needTraining++;
                allClubsReady = false;
            } else if (
                club.descendantRoles.clubContact.fields.primaryContact &&
                club.descendantRoles.clubContact.fields.trainingComplete !== true
            ) {
                // primary contact for the club has not completed training
                needTraining++;
                allClubsReady = false;
            } else if (
                club.fields.surveyMethod === 'paper' &&
                club.fields.trackingNumbers === null
            ) {
                // club is using paper but they have not been shipped
                allClubsReady = false;
                return true;
            } else {
                // club is participating this year
                // primary contact has completed training
                // club is using online method or the paper copies have been shipped
                clubsReady++;
            }
        });

        if (clubs.length === 0) {
            allClubsReady = false;
        }

        if (isCsv) {
            allClubsReady = allClubsReady ? 'Yes' : 'No';
        } else {
            allClubsReady = allClubsReady ? <IconCheckmark /> : <IconWarning />;
        }

        let valueToDisplay = clubsReady;

        switch (settings.value) {
            case 'clubsReady':
                valueToDisplay = clubsReady;
                break;
            case 'needTraining':
                valueToDisplay = needTraining;
                break;
            case 'allClubsReady':
                valueToDisplay = allClubsReady;
                break;
            default:
                valueToDisplay = clubsReady;
        }

        return <div>{valueToDisplay}</div>;
    };
};
