import React from 'react';
import IconWarning from 'cccisd-icons/warning2';
import IconCheckmark from 'cccisd-icons/checkmark';
import _isNull from 'lodash/isNull';

/*
 * Displays the value based on training required and training complete:
 *
 * training required == yes:
 *     - training complete = yes => checkmark
 *     - training complete = no => warning
 *
 * training required == no:
 *     - training complete = yes => 1
 *     - training completed = no => n/a
 */
export default settings => {
    return ({ value, row }) => {
        // training is completed
        if (value === true || value === 1) {
            return (
                <div>
                    <IconCheckmark />
                </div>
            );
        }

        // training not required
        if (
            row['fields.trainingRequired'] === false ||
            (row['fields.trainingRequired'] === null && _isNull(row['fields.trainingComplete']))
        ) {
            return <div>n/a</div>;
        }

        // training is required but not completed
        return (
            <div>
                <IconWarning />
            </div>
        );
    };
};
