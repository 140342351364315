import React from 'react';
import { makeDownloadButton } from 'cccisd-tabletop';
import clubContactsQuery from './clubContactsQuery.graphql';
import style from './style.css';

export default class ClubActivationDownload extends React.Component {
    getDownloadColumns = () => {
        return [
            { label: 'First Name', name: 'user.firstName' },
            { label: 'Last Name', name: 'user.lastName' },
            { label: 'Email', name: 'user.username' },
            { label: 'Primary Contact', name: 'fields.primaryContact' },
            { label: 'Club', name: 'ancestorGroups.club.fields.clubId' },
            { label: 'Last Login', name: 'user.lastLoginDate' },
            { label: 'Invited Date', name: 'user.invitedDate' },
            { label: 'Activation URL', name: 'user.activationUrl' },
        ];
    };

    renderDownloadButton = () => {
        const data = {
            tableProps: {
                columns: this.getDownloadColumns(),
                csvFilename: 'club_contacts_activiation.csv',
                query: clubContactsQuery,
            },
            buttonName: 'Download Activation Information',
            buttonClass: 'btn btn-primary',
        };

        const button = makeDownloadButton(data);
        const getActualVariables = () => {
            return {
                graphqlVariables: {
                    orderBy: [{ field: 'user.firstName', direction: 'ASC' }],
                },
            };
        };

        const Button = button({ getActualVariables });
        return Button;
    };

    render() {
        return <span className={style.btn}>{this.renderDownloadButton()}</span>;
    }
}
