import React from 'react';
import { makeDownloadButton } from 'cccisd-tabletop';
import staffSurveyQuery from './staffSurveyQuery.graphql';
import style from './style.css';

export default class OrgStaffSurveyDownload extends React.Component {
    getDownloadColumns = () => {
        return [
            { label: 'Org Id', name: 'fields.orgId' },
            { label: 'Organization', name: 'group.label' },
            {
                label: 'Primary Organization Admin Name',
                name: 'descendantRoles.orgAdmin.user.fullName',
            },
            {
                label: 'Primary Organization Admin Email',
                name: 'descendantRoles.orgAdmin.user.email',
            },
            { label: 'Staff Survey URL', name: 'fields.staffSurveyLink' },
        ];
    };

    renderDownloadButton = () => {
        const data = {
            tableProps: {
                columns: this.getDownloadColumns(),
                csvFilename: 'org_staff_survey_info.csv',
                query: staffSurveyQuery,
            },
            buttonName: 'Download Staff Survey Links',
            buttonClass: 'btn btn-primary',
        };

        const button = makeDownloadButton(data);
        const getActualVariables = () => {
            return {
                graphqlVariables: {
                    orderBy: [{ field: 'fields.orgId', direction: 'ASC' }],
                },
            };
        };

        const Button = button({ getActualVariables });
        return Button;
    };

    render() {
        return <span className={style.btn}>{this.renderDownloadButton()}</span>;
    }
}
