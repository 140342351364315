import React from 'react';
import { Route } from 'cccisd-laravel-boilerplate';
import defaultProps from 'recompose/defaultProps.js';
import { Switch } from 'react-router-dom';
import { AdminRoutes, Dashboard as AppdefDashboard, Manage as AppdefManage, Gui } from 'cccisd-laravel-appdefs';

import { Quest } from 'cccisd-laravel-assignment';

// Layouts
import FixedLayout from './layouts/FixedLayout';
import UberMainLayout from './layouts/UberMainLayout';
import ClubLayout from './layouts/ClubLayout';

// Pages
/* eslint-disable import/no-extraneous-dependencies, import/no-unresolved */
import Dashboard from 'bundle-loader?lazy!./components/Dashboard';
import Manage from 'bundle-loader?lazy!./components/Manage';
import AuthRedirect from 'bundle-loader?lazy!./components/AuthRedirect';
import PickClub from 'bundle-loader?lazy!./pages/PickClub';
import PreselectClub from 'bundle-loader?lazy!./pages/PreselectClub';
import PickSurvey from 'bundle-loader?lazy!./pages/PickSurvey';
import SetSurveyType from 'bundle-loader?lazy!./pages/SetSurveyType';
import Registration from 'bundle-loader?lazy!./pages/Registration';
import Admin from 'bundle-loader?lazy!./pages/Admin';
import Welcome from 'bundle-loader?lazy!./pages/Welcome';
import SiteContent from 'bundle-loader?lazy!./pages/SiteContent';
import AdminCenter from 'bundle-loader?lazy!./pages/AdminCenter';
import Demo from 'bundle-loader?lazy!./pages/Demo';
import Staff from 'bundle-loader?lazy!./pages/Staff';
import { ResourceCenter, ResourceManager } from 'cccisd-laravel-resources';
import ExportData from 'bundle-loader?lazy!./pages/ExportData';
import QuestPreviewList from './pages/QuestPreviewList';

// Laravel packages
import Nexus from 'bundle-loader?lazy!cccisd-laravel-nexus';

// Bind FixedLayout by default
const AppRoute = defaultProps({ layout: FixedLayout })(Route);
const layoutMatch = { UberMainLayout };

const AppDefinitions = window.cccisd.appDefs;
const Fortress = window.cccisd.fortress;

const match = {
    AppdefDashboard,
    AppdefManage,
    Dashboard,
    Manage,
    ResourceCenter,
    SiteContent,
};

export default () => (
    <Switch>
        {AppDefinitions.routes.map(route => {
            const Component = match[route.componentHandle];
            if (!Component) {
                return null;
            }

            return (
                <AppRoute
                    key={route.handle}
                    path={route.url}
                    component={Component}
                    componentProps={route.componentProps}
                    exact
                    layout={'layout' in route ? layoutMatch[route.layout] : FixedLayout}
                />
            );
        })}
        <AppRoute path="/" component={Welcome} exact layout={ClubLayout} />
        <AppRoute
            path="/organizer"
            component={PickClub}
            exact
            layout={ClubLayout}
            layoutProps={{ inBox: true, showMenu: false }}
        />
        <AppRoute
            path="/organizer/plain"
            component={SetSurveyType}
            componentProps={{ isPlainSurvey: true }}
            exact
            layout={null}
        />
        <AppRoute
            path="/organizer/normal"
            component={SetSurveyType}
            componentProps={{ isPlainSurvey: false }}
            exact
            layout={null}
        />
        <AppRoute path="/member" component={PickSurvey} exact layout={null} />
        <AppRoute path="/member/:clubAccessCode/:hash" component={PreselectClub} exact layout={null} />
        <AppRoute path="/staff/:code?" component={Staff} exact layout={null} />

        <AppRoute
            path="/helpCenterAdmin/:path?"
            component={Gui}
            componentProps={{ path: 'helpCenters.list[0]', title: 'Help Center' }}
            exact
        />

        {Fortress.auth() && (
            <AppRoute path="/registration" component={Registration} layoutProps={{ showSecondaryNav: false }} exact />
        )}
        {Fortress.auth() && (
            <AppRoute path="/admin" component={Admin} layoutProps={{ showSecondaryNav: false }} exact />
        )}
        {Fortress.auth() && <AppRoute path="/adminCenter" component={AdminCenter} exact />}
        {Fortress.auth() && <AppRoute path="/resource" component={ResourceCenter} handle="default" />}
        {Fortress.auth() && <AppRoute path="/demo" component={Demo} layoutProps={{ showSecondaryNav: false }} exact />}
        <AppRoute path="/previewList" component={QuestPreviewList} layout={FixedLayout} />
        <AppRoute
            path="/authRedirect"
            component={AuthRedirect}
            exact
            layout={ClubLayout}
            layoutProps={{ inBox: true }}
        />

        <AppRoute path={['/quest/preview', '/d', '/survey']} component={Quest} layout={null} />

        {/* Laravel packages routes */}
        {AdminRoutes(UberMainLayout)}
        {Fortress.hasAccess('resources.admin') && (
            <AppRoute path="/resources" component={ResourceManager} layout={UberMainLayout} />
        )}
        {/* Show Resource Admin for Uberadmins */}
        {Fortress.hasRole('uberadmin') && (
            <AppRoute path="/exportData" component={ExportData} layout={UberMainLayout} />
        )}

        <AppRoute path="/account" component={Nexus} layout={ClubLayout} layoutProps={{ inBox: true }} />
        {/* Not found page */}
        <AppRoute
            component={AuthRedirect}
            componentProps={{ isComponentOnly: true }}
            layout={ClubLayout}
            layoutProps={{ inBox: true }}
        />
    </Switch>
);
