import PropTypes from 'prop-types';
import React from 'react';
import { reduxForm } from 'redux-form';
import { TextBlock, FieldBlock, domOnlyProps } from 'cccisd-redux-form-helpers';
import ClickButton from 'cccisd-click-button';
import Toggle from 'js/components/Toggle';
import { surveyMethodOptions, surveyMethodLastYearOptions, consentFormOptions } from './config.js';
import isInt from 'validator/lib/isInt';
import { getAllowPaper } from 'js/selectors/params.js';
import { connect } from 'react-redux';
import style from './style.css';
import _get from 'lodash/get';

const currentUserRole = _get(window, 'cccisd.fortress.user.acting.data_type');

const fields = [
    'groupId',
    'clubId',
    'clubName',
    'paperEligible',
    'participatingThisYear',
    'surveyMethod',
    'consentForm',

    'surveyMethodLastYear',
    'youthResponsesCollectedLastYear',
    'teenResponsesCollectedLastYear',

    'paperReturnEnglishYouthLastYear',
    'paperReturnSpanishYouthLastYear',
    'paperReturnEnglishTeenLastYear',
    'paperReturnSpanishTeenLastYear',

    'paperOrderEnglishYouthLastYear',
    'paperOrderSpanishYouthLastYear',
    'paperOrderEnglishTeenLastYear',
    'paperOrderSpanishTeenLastYear',

    'paperOrderEnglishYouth',
    'paperOrderSpanishYouth',
    'paperOrderEnglishTeen',
    'paperOrderSpanishTeen',

    'maxYouth',
    'maxTeen',
    'maxOptionalModulesAllowed',

    'allModules',

    'riskBehaviorModuleAllowed',
    'riskBehaviorModuleRequired',
    'riskBehaviorModuleSelected',
    'riskBehaviorModuleSelectedLastYr',

    'artsModuleAllowed',
    'artsModuleRequired',
    'artsModuleSelected',
    'artsModuleSelectedLastYear',

    'stemModuleAllowed',
    'stemModuleRequired',
    'stemModuleSelected',
    'stemModuleSelectedLastYear',

    'bullyingModuleAllowed',
    'bullyingModuleRequired',
    'bullyingModuleSelected',
    'bullyingModuleSelectedLastYear',

    'legacyModuleAllowed',
    'legacyModuleRequired',
    'legacyModuleSelected',
    'legacyModuleSelectedLast',

    'essentialSkillsModuleAllowed',
    'essentialSkillsModuleRequired',
    'essentialSkillsModuleSelected',
    'essentialSkillsModuleSelLastYr',
];
export const numberFields = [
    'youthResponsesCollectedLastYear',
    'teenResponsesCollectedLastYear',
    'paperReturnEnglishYouthLastYear',
    'paperReturnSpanishYouthLastYear',
    'paperReturnEnglishTeenLastYear',
    'paperReturnSpanishTeenLastYear',
    'paperOrderEnglishYouthLastYear',
    'paperOrderSpanishYouthLastYear',
    'paperOrderEnglishTeenLastYear',
    'paperOrderSpanishTeenLastYear',
    'paperOrderEnglishYouth',
    'paperOrderSpanishYouth',
    'paperOrderEnglishTeen',
    'paperOrderSpanishTeen',
    'maxYouth',
    'maxTeen',
];

export const validate = (values, props = {}) => {
    const errors = {};

    numberFields.forEach(field => {
        if (
            typeof values[field] === 'undefined' ||
            values[field] === null ||
            !isInt(values[field].toString(), { min: 0, max: 1000 })
        ) {
            errors[field] = 'Value has to be a whole number from 0 to 1000';
        }
    });

    if (!values.maxOptionalModulesAllowed || !isInt(values.maxOptionalModulesAllowed.toString(), { min: 1, max: 7 })) {
        errors.maxOptionalModulesAllowed = 'Value has to be a whole number from 1 to 7';
    } else {
        const selectedModules = props.modules.filter(item => values[`${item.code}ModuleSelected`]);
        if (values.maxOptionalModulesAllowed < selectedModules.length) {
            errors.allModules = `You cannot select more than ${values.maxOptionalModulesAllowed} modules`;
        }
    }

    if (!values.groupId) {
        errors.groupId = 'Organization is required';
    }
    if (!props.allowPaper && values.surveyMethod === 'paper' && currentUserRole !== 'uberadmin') {
        errors.surveyMethod = 'The deadline for using paper has passed. Surveys must be completed online.';
    }
    if (!values.clubId) {
        errors.clubId = 'Club ID is required';
    }
    if (!values.clubName) {
        errors.clubName = 'Club Name is required';
    }

    return errors;
};

class Form extends React.Component {
    static propTypes = {
        organizations: PropTypes.array.isRequired,
        fields: PropTypes.object.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        submitting: PropTypes.bool.isRequired,
        modules: PropTypes.array.isRequired,
    };

    Group = props => (
        <div className={style.group}>
            <div className={style.title}>{props.title}</div>
            {props.children}
        </div>
    );

    onAllowedRequiredSelectedChange = (field, event) => {
        const fieldName = field.name;

        // If field is "required" then change "allowed" and "selected" as well
        if (/Required$/.test(fieldName) && field.value === false) {
            const allowedFieldName = fieldName.replace('Required', 'Allowed');
            this.props.fields[allowedFieldName].onChange(true);

            const selectedFieldName = fieldName.replace('Required', 'Selected');
            this.props.fields[selectedFieldName].onChange(true);
        }

        // If field is "allowed" then change "required" and "selected" as well
        if (/Allowed$/.test(fieldName) && field.value === true) {
            const requiredFieldName = fieldName.replace('Allowed', 'Required');
            this.props.fields[requiredFieldName].onChange(false);

            const selectedFieldName = fieldName.replace('Allowed', 'Selected');
            this.props.fields[selectedFieldName].onChange(false);
        }

        // If field is not "selected" then change "required" as well
        if (/Selected$/.test(fieldName) && field.value === true) {
            const requiredFieldName = fieldName.replace('Selected', 'Required');
            this.props.fields[requiredFieldName].onChange(false);
        }

        // If field is "selected" then change "allowed" as well
        if (/Selected$/.test(fieldName) && field.value === false) {
            const allowedFieldName = fieldName.replace('Selected', 'Allowed');
            this.props.fields[allowedFieldName].onChange(true);
        }

        field.onChange(event);
    };

    render() {
        const {
            fields: {
                groupId,
                clubId,
                clubName,
                participatingThisYear,
                paperEligible,
                surveyMethod,
                consentForm,
                surveyMethodLastYear,
                youthResponsesCollectedLastYear,
                teenResponsesCollectedLastYear,
                paperOrderEnglishYouthLastYear,
                paperOrderSpanishYouthLastYear,
                paperOrderEnglishTeenLastYear,
                paperOrderSpanishTeenLastYear,
                paperReturnEnglishYouthLastYear,
                paperReturnSpanishYouthLastYear,
                paperReturnEnglishTeenLastYear,
                paperReturnSpanishTeenLastYear,
                paperOrderEnglishYouth,
                paperOrderSpanishYouth,
                paperOrderEnglishTeen,
                paperOrderSpanishTeen,
                maxYouth,
                maxTeen,
                maxOptionalModulesAllowed,
            },
            handleSubmit,
            submitting,
            errors,
            organizations,
        } = this.props;

        const modules = [
            {
                code: 'riskBehavior',
                name: 'Risk Behavior',
                selectedLastYearField: 'riskBehaviorModuleSelectedLastYr',
            },
            { code: 'arts', name: 'Arts' },
            { code: 'stem', name: 'STEM' },
            { code: 'bullying', name: 'Bullying' },
            { code: 'legacy', name: 'Legacy' },
            {
                code: 'essentialSkills',
                name: 'Essential Skills',
                selectedLastYearField: 'essentialSkillsModuleSelLastYr',
            },
        ];

        return (
            <form onSubmit={handleSubmit}>
                <FieldBlock field={groupId} label="Organization Name">
                    <select {...domOnlyProps(groupId)} className="form-control" style={{ width: 'auto' }}>
                        <option value="">--</option>
                        {organizations.map(option => (
                            <option key={option.groupId} value={option.groupId}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                </FieldBlock>

                <div className={style.grid}>
                    <TextBlock field={clubId} label="Club ID" style={{ maxWidth: '100px' }} />
                    <div style={{ flexGrow: 1 }}>
                        <TextBlock field={clubName} label="Club Name" />
                    </div>
                </div>

                <FieldBlock field={participatingThisYear}>
                    <Toggle {...participatingThisYear} label="Participating this year" />
                </FieldBlock>

                <div className={style.grid}>
                    <FieldBlock field={surveyMethod} label="Survey method">
                        <select {...domOnlyProps(surveyMethod)} className="form-control" style={{ width: 'auto' }}>
                            {surveyMethodOptions.map(option => (
                                <option key={option.value} value={option.value}>
                                    {option.name}
                                </option>
                            ))}
                        </select>
                    </FieldBlock>

                    <FieldBlock field={consentForm} label="Permission Form">
                        <select {...domOnlyProps(consentForm)} className="form-control" style={{ width: 'auto' }}>
                            {consentFormOptions.map(option => (
                                <option key={option.value} value={option.value}>
                                    {option.name}
                                </option>
                            ))}
                        </select>
                    </FieldBlock>
                </div>

                <FieldBlock field={paperEligible}>
                    <Toggle {...paperEligible} label="Eligible for paper" />
                </FieldBlock>

                <FieldBlock field={surveyMethodLastYear} label="Survey method last year">
                    <select {...domOnlyProps(surveyMethodLastYear)} className="form-control" style={{ width: 'auto' }}>
                        {surveyMethodLastYearOptions.map(option => (
                            <option key={option.value} value={option.value}>
                                {option.name}
                            </option>
                        ))}
                    </select>
                </FieldBlock>

                <div className="row">
                    <div className="col-xs-4">
                        <TextBlock
                            field={youthResponsesCollectedLastYear}
                            label="Youth responses collected last year"
                        />
                    </div>

                    <div className="col-xs-4">
                        <TextBlock field={teenResponsesCollectedLastYear} label="Teen responses collected last year" />
                    </div>
                </div>

                <this.Group title="Paper surveys returned last year">
                    <div className={style.grid}>
                        <TextBlock
                            type="number"
                            field={paperReturnEnglishYouthLastYear}
                            label="English youth"
                            style={{ maxWidth: '100px' }}
                        />

                        <TextBlock
                            type="number"
                            field={paperReturnSpanishYouthLastYear}
                            label="Spanish youth"
                            style={{ maxWidth: '100px' }}
                        />

                        <TextBlock
                            type="number"
                            field={paperReturnEnglishTeenLastYear}
                            label="English teen"
                            style={{ maxWidth: '100px' }}
                        />

                        <TextBlock
                            type="number"
                            field={paperReturnSpanishTeenLastYear}
                            label="Spanish teen"
                            style={{ maxWidth: '100px' }}
                        />
                    </div>
                </this.Group>

                <this.Group title="Paper survey order last year">
                    <div className={style.grid}>
                        <TextBlock
                            type="number"
                            field={paperOrderEnglishYouthLastYear}
                            label="English youth"
                            style={{ maxWidth: '100px' }}
                        />

                        <TextBlock
                            type="number"
                            field={paperOrderSpanishYouthLastYear}
                            label="Spanish youth"
                            style={{ maxWidth: '100px' }}
                        />

                        <TextBlock
                            type="number"
                            field={paperOrderEnglishTeenLastYear}
                            label="English teen"
                            style={{ maxWidth: '100px' }}
                        />

                        <TextBlock
                            type="number"
                            field={paperOrderSpanishTeenLastYear}
                            label="Spanish teen"
                            style={{ maxWidth: '100px' }}
                        />
                    </div>
                </this.Group>

                <div className="row">
                    <div className="col-xs-4">
                        <TextBlock type="number" field={maxYouth} label="Paper max youth allowed" />
                    </div>

                    <div className="col-xs-4">
                        <TextBlock type="number" field={maxTeen} label="Paper max teen allowed" />
                    </div>

                    <div className="col-xs-4">
                        <TextBlock
                            type="number"
                            field={maxOptionalModulesAllowed}
                            label="Max number of modules allowed"
                        />
                    </div>
                </div>

                <this.Group title="Paper survey order this year">
                    <div className={style.grid}>
                        <TextBlock
                            type="number"
                            field={paperOrderEnglishYouth}
                            label="English youth"
                            style={{ maxWidth: '100px' }}
                        />

                        <TextBlock
                            type="number"
                            field={paperOrderSpanishYouth}
                            label="Spanish youth"
                            style={{ maxWidth: '100px' }}
                        />

                        <TextBlock
                            type="number"
                            field={paperOrderEnglishTeen}
                            label="English teen"
                            style={{ maxWidth: '100px' }}
                        />

                        <TextBlock
                            type="number"
                            field={paperOrderSpanishTeen}
                            label="Spanish teen"
                            style={{ maxWidth: '100px' }}
                        />
                    </div>
                </this.Group>

                <this.Group title="Optional module settings">
                    {errors.allModules && <p className="text-danger">{errors.allModules}</p>}

                    <table
                        className={'table table-bordered table-striped ' + style.table}
                        style={{ width: 'auto', marginBottom: '0' }}
                    >
                        <thead>
                            <tr>
                                <th>Module</th>
                                <th>Allowed</th>
                                <th>Required</th>
                                <th>Selected</th>
                                <th>Selected last year</th>
                            </tr>
                        </thead>
                        <tbody>
                            {modules.map(item => {
                                const allowedField = this.props.fields[`${item.code}ModuleAllowed`];
                                const requiredField = this.props.fields[`${item.code}ModuleRequired`];
                                const selectedField = this.props.fields[`${item.code}ModuleSelected`];
                                const selectedLastYearField =
                                    this.props.fields[
                                        item.selectedLastYearField || `${item.code}ModuleSelectedLastYear`
                                    ];

                                return (
                                    <tr key={item.code}>
                                        <td>{item.name}</td>
                                        <td>
                                            <div className={style.center}>
                                                <Toggle
                                                    {...allowedField}
                                                    id={`${item.code}ModuleAllowed`}
                                                    onChange={this.onAllowedRequiredSelectedChange.bind(
                                                        this,
                                                        allowedField
                                                    )}
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div className={style.center}>
                                                <Toggle
                                                    {...requiredField}
                                                    id={`${item.code}ModuleRequired`}
                                                    onChange={this.onAllowedRequiredSelectedChange.bind(
                                                        this,
                                                        requiredField
                                                    )}
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div className={style.center}>
                                                <Toggle
                                                    {...selectedField}
                                                    id={`${item.code}ModuleSelected`}
                                                    onChange={this.onAllowedRequiredSelectedChange.bind(
                                                        this,
                                                        selectedField
                                                    )}
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div className={style.center}>
                                                <Toggle
                                                    {...selectedLastYearField}
                                                    id={
                                                        item.selectedLastYearField ||
                                                        `${item.code}ModuleSelectedLastYear`
                                                    }
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </this.Group>
                <ClickButton className="btn btn-primary" onClick={handleSubmit} isLoading={submitting} title="Submit" />
            </form>
        );
    }
}

const mapStateToProps = state => ({
    allowPaper: getAllowPaper(state.app.params),
});

export default connect(mapStateToProps)(
    reduxForm({
        form: 'BigRegistrationForm',
        fields,
        validate,
    })(Form)
);
