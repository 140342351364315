import React from 'react';
import _get from 'lodash/get';
import { client as apollo } from 'cccisd-apollo';
import Loader from 'cccisd-loader';
import style from './style.css';
import assignmentInfoQuery from './assignmentInfo.graphql';
import { generateAssignmentPreviewLink } from 'cccisd-laravel-assignment';

const previewAssignmentHandles = ['assignment3', 'assignment4'];

export default class QuestPreviewList extends React.Component {
    state = {
        isLoading: true,
        previewAssignments: [],
    };

    /* /////////////////////////////////////////////////////////////////////////
    // REACT LIFECYCLE METHODS ////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////// */

    componentDidMount() {
        this.loadAssignmentInfo();
    }

    /* /////////////////////////////////////////////////////////////////////////
    // CLASS METHODS //////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////// */

    loadAssignmentInfo = async () => {
        let result = await apollo.query({
            query: assignmentInfoQuery,
            variables: { assignmentHandles: previewAssignmentHandles },
            fetchPolicy: 'network-only',
        });

        this.setState({
            isLoading: false,
            previewAssignments: _get(result, 'data.flows.assignmentList', []),
        });
    };

    /* /////////////////////////////////////////////////////////////////////////
    // RENDER /////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////// */

    render() {
        const { isLoading, previewAssignments } = this.state;
        if (isLoading) {
            return <Loader loading />;
        }

        const previewList = previewAssignments.map(assignmentObj => {
            return (
                <li key={assignmentObj.assignmentId}>
                    <a
                        href={generateAssignmentPreviewLink(assignmentObj.assignmentId)}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {assignmentObj.label}
                    </a>
                </li>
            );
        });

        return (
            <div className={style.wrapper}>
                <div>
                    <h2>Member Surveys</h2>
                    <ul>{previewList}</ul>
                </div>
            </div>
        );
    }
}
