/* eslint-disable global-require */
import React from 'react';
import PropTypes from 'prop-types';
import Footer from 'cccisd-footer';
import { isIE } from 'js/utils.js';
import classnames from 'classnames';
import Header from 'js/components/Header';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import style from './style.css';

export const images = [
    require('./CatConfused.svg'),
    require('./CatYay.svg'),
    require('./CatHandOnHip.svg'),
    require('./BuckConfused.svg'),
    require('./BuckProud.svg'),
];

const FixedLayout = props => {
    const {
        characters,
        isBackgroundOpacity,
        showSaveButton,
        saveButtonUrl,
        inBox,
        logoInBox,
        children,
        showPolicyLinks,
    } = props;

    const clubInfo = useSelector(state => state.app.bgClub?.clubInfo);
    const location = useLocation();
    const clubName = clubInfo?.label;

    const showClubName = clubName && location.pathname.includes('/member');

    return (
        <div
            className={classnames(style.body, {
                [style.opacity]: isBackgroundOpacity,
            })}
        >
            {characters.map(character => (
                <div key={character} className={`bgca-character ${style.character} ${style[character]}`} />
            ))}
            <div className={style.content}>
                <Header containerClassName="container" showSaveButton={showSaveButton} saveButtonUrl={saveButtonUrl} />
                {showClubName && (
                    <div className={style.clubName}>
                        <div>{clubName}</div>
                    </div>
                )}
                <div className="container">
                    <div className={classnames({ [style.box]: inBox })}>
                        {inBox && logoInBox && (
                            <div>
                                <div className={style.logo} />
                                <div className={style.title1}>Boys & Girls Clubs</div>
                                <div className={style.title2}>of America</div>
                            </div>
                        )}
                        {children}
                    </div>
                </div>
            </div>
            {!isIE() && (
                <div className={style.footer}>
                    <Footer className="container" showPolicyLinks={showPolicyLinks} showContactUsLink={false} />
                </div>
            )}
        </div>
    );
};

FixedLayout.propTypes = {
    children: PropTypes.node,
    showMenu: PropTypes.bool,
    inBox: PropTypes.bool,
    logoInBox: PropTypes.bool,
    showSaveButton: PropTypes.bool,
    showHelpLink: PropTypes.bool,
    showPolicyLinks: PropTypes.bool,
    characters: PropTypes.array,
    isBackgroundOpacity: PropTypes.bool,
    saveButtonUrl: PropTypes.string,
};

FixedLayout.defaultProps = {
    characters: [],
    isBackgroundOpacity: false,
};

export default FixedLayout;
