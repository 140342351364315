import React from 'react';
import { makeDownloadButton } from 'cccisd-tabletop';
import orgAdminsQuery from './orgAdminsQuery.graphql';
import style from './style.css';

export default class DownloadAllOrgAdminInfo extends React.Component {
    getDownloadColumns = () => {
        return [
            { label: 'Organization_Id', name: 'ancestorGroups.organization.fields.orgId' },
            { label: 'Organization_Name', name: 'ancestorGroups.organization.group.label' },
            { label: 'username', name: 'user.username' },
            { label: 'first_name', name: 'user.firstName' },
            { label: 'last_name', name: 'user.lastName' },
            { label: 'email', name: 'user.email' },
            { label: 'phone', name: 'user.phone' },
            { label: 'primaryContact', name: 'fields.primaryContact' },
            { label: 'Position_Title', name: 'fields.positionTitle' },
            { label: 'Training_Required', name: 'fields.trainingRequired' },
            { label: 'Training_Complete', name: 'fields.trainingComplete' },
        ];
    };

    renderDownloadButton = () => {
        const data = {
            tableProps: {
                columns: this.getDownloadColumns(),
                csvFilename: 'org_admins.csv',
                query: orgAdminsQuery,
            },
            buttonName: 'Download Info On All Org Admins',
            buttonClass: 'btn btn-primary',
        };

        const button = makeDownloadButton(data);
        const getActualVariables = () => {
            return {
                graphqlVariables: {
                    orderBy: [
                        { field: 'ancestorGroups.organization.fields.orgId', direction: 'ASC' },
                    ],
                },
            };
        };

        const Button = button({ getActualVariables });
        return Button;
    };

    render() {
        return <span className={style.btn}>{this.renderDownloadButton()}</span>;
    }
}
