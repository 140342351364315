const appdefs = window.cccisd.appDefs;

const getOptionsFromAppDefs = handle => {
    if (!appdefs) {
        return [];
    }

    const field = appdefs.pawn.fields.find(item => item.handle === handle);
    return field ? field.values : [];
};

export const surveyMethodOptions = getOptionsFromAppDefs('surveyMethod');
export const surveyMethodLastYearOptions = getOptionsFromAppDefs('surveyMethodLastYear');
export const consentFormOptions = getOptionsFromAppDefs('consentForm');
