import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import CccisdHeader from 'cccisd-header';
import IconQuestion from 'cccisd-icons/svg/question4';
import IconBook from 'cccisd-icons/svg/book3';
import Modal from 'cccisd-modal';
import HelpCenter from 'cccisd-help-center';
import Tutorial from 'js/components/Tutorial';
import style from './style.css';

const Boilerplate = window.cccisd.boilerplate;

const Header = props => {
    const { showSaveButton, saveButtonUrl } = props;

    const helpModalRef = useRef();
    const tutorialModalRef = useRef();

    const primaryNavLinks = [
        {
            url: '/needHelp',
            label: 'Need Help',
            icon: IconQuestion,
            action: () => helpModalRef.current.open(),
        },
        {
            url: '/tutorial',
            label: <b>Registration Tutorial</b>,
            icon: IconBook,
            action: () => tutorialModalRef.current.open(),
        },
    ];

    if (showSaveButton) {
        return (
            <div className={style.memberHeaderWrapper}>
                <div className="container">
                    <div className={style.memberHeader}>
                        <div className={style.logo} />
                        <div>
                            <a href={Boilerplate.url(saveButtonUrl)} className="btn btn-primary">
                                Save and exit
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <CccisdHeader
                logoClassName={style.logo}
                primaryNavLinks={primaryNavLinks}
                secondaryNavLinks="mainNav"
                showHomeAction={false}
                showAlertAction={false}
                {...props}
            />
            <Modal ref={helpModalRef} trigger={null} title="Need Help?" size="large">
                <HelpCenter helpCenter="default" />
            </Modal>
            <Modal ref={tutorialModalRef} trigger={null} title="Registration Tutorial" size="large">
                <Tutorial />
            </Modal>
        </>
    );
};

Header.propTypes = {
    showSaveButton: PropTypes.bool,
    saveButtonUrl: PropTypes.string,
};

Header.defaultProps = {
    saveButtonUrl: 'member',
};

export default Header;
